/*
Colors Kometa
*/

$color_1: #060606;
$color_2: #b4005d;
$color_3: #2cccff;
$color_4: #1901e4;
$color_5: #727272;
$color_6: #fe25d7;
$color_7: #008bb7;
$color_8: #100475;
$color_9: #ff0374;

header.banner.content{
    background: #070707;
    padding-bottom: 20px;
  }

#cometa-header {
    background: linear-gradient(180deg, #070707 49.48%, #2B2B2B 99.7%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    padding: 0;
}

#cometa-header-image, #cometa-header-image picture {
    align-items: center;
    display: flex;
    justify-content: center;
}

#cometa-header-image img {
    width: 100%;
}


#cometa-text{
    background: $color_3;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.75;
    margin: 0 auto;
    padding: 0 25px 25px;
    text-align: justify;
    padding: 50px;
    margin-top: -80px;
    border-top-left-radius: 8vh;
    border-top-right-radius: 8vh;
    position: relative;
    z-index: 2;
}

#cometa-text h2 {
    font-size: 1.5rem;
    font-weight: bold;
}
#cometa-text h3 {
    font-size: 1.5rem;
}

#cometa-text p {
    padding-top: 20px;
}

#audio-player{
    display: flex;
    position: fixed;
    bottom: 0;
    align-items: center;
    width: 100%;
    height: 60px;
    background: #070707; 
    z-index: 9999; 
}

#audio-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.audio-button{
    border-radius: 50%;
    height: 30px;
    width: 30px; 
    border: 0px; 
    background-size: contain;
    background-color: transparent;
}

#play-button{
    background-image: url(../images/play_icon.png)
}
#pause-button{
    background-image: url(../images/pause_icon.png)
}

#previous-song{
    background-image: url(../images/previous_song_icon.png);
    border-radius: 0;
    height: 20px;
    width: 20px;
    margin-right: 15px;
    background-repeat: no-repeat;
}

#next-song{
    background-image: url(../images/next_song_icon.png);
    border-radius: 0;
    height: 20px;
    width: 20px;
    margin-left: 15px;
    background-repeat: no-repeat;
}

#audio_name{
    color: #fff;
    padding: 0 50px;
    position: absolute;
}