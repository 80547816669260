$headline-color: #fff;
$button-color: #fff;
$ghost-button-border: 2px solid #fff;


/*
Colors Kometa
*/

$color_1: #060606;
$color_2: #b4005d;
$color_3: #2cccff;
$color_4: #1901e4;
$color_5: #727272;
$color_6: #fe25d7;
$color_7: #008bb7;
$color_8: #100475;
$color_9: #ff0374;


 html, body, #root {
    height: 100%;
  }

 strong{
  font-weight: bold;
 }
  button:focus {
    outline: none;
  }

  .wrapper {
    height: 100%;
  }

  header {
    height: 10%;
  }

/**

  Nav

**/

header.banner {
    position: relative;
    top: 0;
    z-index: 100;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  #menu-toggle {
    position: relative;
    width: 32px;
    height: 24px;
    cursor: pointer;
    background: transparent;
    border: none;

  }

  #menu {
    position: fixed;
    top: 0;
    display: none;
    width: 100%;
    height: 100%;
    transition: left 0.3s ease, box-shadow 0.3s ease;
    z-index: 2;
    background: #2cccff;
  }

  .open {
    display: flex!important;
    flex-direction: column;
  }

  #menu ul {
    list-style: none;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #menu a {
    display: block;
    padding: .55em 15px;
    line-height: 1em;
    font-size: 1em;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.4rem;
  }

  $height: 36;
label {
  position: relative;
  display: inline-block;
  &:before {
    content: '';
    height: ($height - 5) + px;
    position: absolute;
    right: 7px;
    top: 3px;
    width: 22px;

    //background: -webkit-linear-gradient(#fff, #f0f0f0);
    //background: -moz-linear-gradient(#fff, #f0f0f0);
    //background: linear-gradient(#f5f5f5, #e0e0e0);
    background: #fff; //for Firefox in Android

    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    pointer-events: none;
    display: block;
  }
  &:after {
    content: " ";
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 2;
    pointer-events: none;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6.9px 4px 0 4px;
    border-color: #2cccff transparent transparent transparent;
    pointer-events: none;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 30px 0 10px;

    border: 1px solid #060606;
    border-radius: 3px;
    line-height: $height + px;
    height: $height + px;
    //box-shadow: inset 1px 1px 1px 0px rgba(0, 0, 0, 0.2);
    background: #fff;

    //min-width: 200px;
    margin: 0 5px 5px 0;
  }
}
//fix for ie 10 later
select::-ms-expand {
  display: none;
}

  .line {
    display: block;
    background: #fff;
    width: 32px;
    height: 2px;
    position: absolute;
    left: 0;
    border-radius: 4px;
    transition: all 0.4s;
  }

  .line.line-1 {
    top: 0;
  }

  .line.line-2 {
    top: 50%;
  }

  .line.line-3 {
    top: 100%;
    width: 24px;
  }

  #menu-toggle:hover+.line1, #menu-toggle:focus+.line1 {
    transform: translateY(-4px);
  }

  #menu-toggle:hover+.line-3, #menu-toggle:focus+.line-3 {
    transform: translateY(4px);
  }

  .line-1-active {
    transform: translateY(12px) translateX(0) rotate(45deg);
    background: #000;
  }

  .line-2-active {
    opacity: 0;
  }

  .line-3-active {
    transform: translateY(-13px) translateX(0) rotate(-45deg);
    background: #000;
      width: 32px!important;
  }

  #logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  #logo p a {
    font-size: .75rem;
    font-weight: bold;
    text-transform: lowercase;
    padding-top: 20px;
  }

  #logo img {
    width: 200px;
  }

  .original-image, #resized-image {
    display: none;
  }


