body {
  font-family: 'Open Sans', sans-serif;
  // background: #CB3376;
  margin: 0;
  height: 100%;
}

#contact-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  height: 90vh;
}

#contact-header {
  height: 50vh;
  background: #cb3376;
  display: flex;
  justify-content: center;
  align-items: center;
}

#contact-main a {
  padding-bottom: 20px;
  color: #fff;
  text-decoration: none;
}

#contact-main a:hover {
  text-decoration: underline;
}

.h1-contact {
  font-size: 3.5rem;
  color: #fff;
  text-transform: uppercase;
  margin-left: 50px;
}

#contact-data {
  margin-top: 50px;
}

#contact-content {
  padding: 50px;
  color: #fff;
  line-height: 1.3;
  height: 50vh;
}

#contact-content ul {
  margin-top: 10px;
}

#contact-content li {
  padding: 10px 0;
}

#contact-content span {
  padding-right: 20px;
}

@media screen and (max-width: 1024px) {
  .h1-contact {
    font-size: 2.5rem;
    margin-left: 0;
  }
  #contact-data {
    margin-top: 0px;
  }
  
}