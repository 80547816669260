/**

  Home

**/

$background-purple: rebeccapurple;
$headline-color: #fff;
$button-color: #fff;
$ghost-button-border: 2px solid #fff;

body {
  font-family: 'Open Sans', sans-serif;
  // background: $background-purple;
  margin: 0;
  height: 100%;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
}

#content-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 25px;
  position: relative;
}

#profile-picture {
  height: 100px;
  width: 100px;
  background: url(../images/Kometa_profil_pretotyp.png) no-repeat;
  border-radius: 75px;
  background-size: cover;
}

#profile-picture {
  background: url(../images/Kometa_profil_pretotyp.png) no-repeat;
  background-size: cover;
}

#headlines h1 {
  font-size: 1.4rem;
  text-align: center;
  color: $headline-color;
  text-transform: uppercase;
  line-height: 1.1;
  padding: 15px 0 5px 0;
}

#headlines h2 {
  font-size: .75rem;
  text-align: center;
  color: $headline-color;
  float: right;
}

#btns-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  height: 85px;
}

.btn {
  font-family: 'Open Sans', sans-serif !important;
  font-family: 'Futura', sans-serif !important;
}

#scan-btn {
  width: 220px;
  padding: 17px;
  background: no-repeat;
  font: inherit;
  border-style: solid;
  border: $ghost-button-border;
  color: $button-color;
  text-transform: uppercase;
  text-align: center;
  box-sizing: border-box;
}

#scan-btn:hover {
  background: #fff;
  color: #000;
  cursor: pointer;
}

#selected-image,
#prediction-list,
input[name="imageToUpload"] {
  display: none;
}

#overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: block;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

#error-message {
  z-index: 3;
  color: black;
  height: 190px;
  width: 310px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);
  padding: 20px;
  box-sizing: border-box;
  flex-direction: column;
  text-align: left;
  border-radius: 4px;
  font-size: 0.9rem;
}

#error-message h3 {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

#error-message p {
  margin-bottom: 20px;
}

#error-btn {
  width: 200px;
  padding: 17px;
  background: no-repeat;
  font: inherit;
  border-style: solid;
  border: 2px solid rebeccapurple;
  color: rebeccapurple;
  text-transform: uppercase;
  text-align: center;
  box-sizing: border-box;
  margin: 0 auto;
}

#error-btn:hover{
  cursor: pointer;
  background: rebeccapurple;
  color: #fff;
}

#error-btn:active, #error-message:focus{
  cursor: pointer;
  background: #fae03c;
  font-weight: bold;
  border: 1px solid #fae03c;
  color:#000;
}


.webcam-div {
  position: fixed;
  margin: 0 auto;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  // width: 100%;
  // height: 100%;
  background: #000;
  z-index: 999;
}

// .overlay-webcam {
//   height: 200px;
//   width: 250px;
//   border: 2px solid #000;
//   z-index: 1000;
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.take-picture {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 1001;
  height: 70px;
  width: 70px;
  background: #fff;
  border-radius: 50%;
}

.photo-button {
  width: 70px;
  height: 70px;
  bottom: 0;
  left: 50%;
  position: fixed;
  z-index: 2000;
  transform: translate(-50%, -50%);
}
.circle {
  position: fixed;
  top: 12%;
  left: 12%;
  bottom: 12%;
  right: 11%;
  border-radius: 100%;
  background-color: #ffffff;
  opacity: 0.8;
  z-index: 2000;
}
.ring {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  border: 0.35em solid #ffffff;
  opacity: 0.8;
  z-index: 2000;
}
.photo-button .circle, .photo-button .ring {
  transition: all 0.25s;
}
.photo-button:hover .circle {
  opacity: 1;
}
.photo-button:active .ring {
  opacity: 1;
  border-color: rebeccapurple;
}
.photo-button:active .circle {
  background: rebeccapurple;
}

#flash{ 
  opacity: 0;
  transition: all 30ms ease-in;/* Shutter speed */
  position: fixed;
  height: 0%;
  width: 0%;
  pointer-events: none;
  background-color: black;/* Shutter Color */
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%)F
}

#flash.on {
  opacity: 1;/* Shutter Transparency */
  height: 100%;
  width: 100%;
  z-index: 2001;
}