$background-purple: rebeccapurple;
$button-color: $background-purple;
$ghost-button-border: 2px solid $background-purple;

#content-header-404 {
    height: 50vh;
    background: rebeccapurple;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.h1-404 {
    font-size: 3.5rem;
    color: #fff;
    text-transform: uppercase;
    margin-left: 50px;
}

.h2-404{
    margin-left: 50px;
    color: #fff;
    margin-top: 20px;
}

#profile-picture-404 {
    height: 100px;
    width: 100px;
    background: url(../images/Rina_404.jpg) no-repeat;
    background: url(../images/Rina_404.webp) no-repeat;
    border-radius: 75px;
    background-size: cover;
  }
  
  .content-404{
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }

  #btn-404{
    width: 200px;
    padding: 17px;
    background: no-repeat;
    font: inherit;
    border-style: solid;
    border: $ghost-button-border;
    color: $button-color;
    text-transform: uppercase;
    text-align: center;
    box-sizing: border-box;
  }

  #btn-404:hover{
    background: $background-purple;
    color: #fff;
    cursor: pointer;
  }

  .content-404 a{
    font-size: 1rem;
    padding-bottom: 0px;
    text-decoration: none;
  }

  .content-404 a:hover{
    font-size: 1rem;
    padding-bottom: 0px;
    text-decoration: none;
  }


  @media screen and (max-width: 1024px) {
    #content-header-404 {
        flex-direction: column;
    }
    #headlines-404 {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .h1-404 {
        font-size: 2.5rem;
        margin-left: 0px;
        text-align: center;
        margin-top: 20px;
    }
    .h2-404{
        margin-left: 0px;
        text-align: center;
        line-height: 1.3;
    }
  }